import { Message } from 'element-ui'
import axios from 'axios'
import router from '@/router';
import store from '@/store/index';
import baseURL from './env';

const service = axios.create({
  // 基础路径
  baseURL,
  // 请求超时时间   （5s内没响应就失败了）
  timeout: 5000
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
  let token = ''
  // notToken 不需要登录的页面不传token
  const { data, params } = config
  if (data?.notToken || params?.notToken) {
    token = ''
  } else {
    token = JSON.parse(localStorage.getItem('userInfo'))?.token
  }
  delete data?.notToken
  delete params?.notToken
  // config.headers['ip'] = returnCitySN["cip"];
  config.headers['token'] = token || ''
  store.commit('changeState', { key: 'loading', state: true })
  return config
}, function (error) {
  store.commit('changeState', { key: 'loading', state: false })
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  store.commit('changeState', { key: 'loading', state: false })
  if (!response?.data) {
    return
  } else if (response?.data?.code === 200) {
    return Promise.resolve(response.data)
  } else if (response?.data?.code === 401) {
    Message({
      message: '登录态失效，即将跳转登录页',
      type: 'error'
    })
    setTimeout(() => {
      router.push('/login')
    }, 1000)
  } else {
    Message({
      message: response?.data?.message,
      type: 'error'
    })
  }
}, function (error) {
  Message({
    message: "网络超时请重试",
    type: 'error'
  })
  store.commit('changeState', { key: 'loading', state: false })
  return Promise.reject(error)
})
export default service