<template>
  <div id="app" v-loading="loading">
    <router-view v-if="isReload" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import homeApi from "@/services/home";

const { fetchSaveEvent } = homeApi;

export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isReload: true,
    };
  },
  mounted() {
    // 每五分钟收集一次
    setInterval(() => {
      this.eventSave();
    }, 1000 * 60 * 5);
  },
  methods: {
    eventSave() {
      const data = JSON.parse(localStorage.getItem("spmState")) || [];
      data?.length &&
        fetchSaveEvent(data).then((res) => {
          localStorage.removeItem("spmState");
        });
    },

    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });
    },
  },
  computed: {
    ...mapState(["loading"]),
  },
};
</script>
<style lang="scss">
@import "@/style/icon.css";
@import "@/style/global.scss";

*body,
div,
ul,
ol,
dl,
dt,
dd,
li,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  // font-style: normal;
  // font: 12px/22px "\5B8B\4F53", Arial, Helvetica, sans-serif;
}

ol,
ul,
li {
  list-style: none;
}

img {
  border: 0;
  vertical-align: middle;
}

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
  width: 100%;
  height: 100%;
  color: #000000;
  background: #e9ecf4;
}

#app {
  width: 100%;
}

a {
  color: #000000;
  text-decoration: none;
}

a:hover {
  color: #ba2636;
  text-decoration: underline;
}

div,
header,
main,
footer {
  box-sizing: border-box;
}

.scroll-box {
  overflow: hidden;
  &:hover {
    overflow: auto;
  }
  &::-webkit-scrollbar {
    width: 2px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    width: 2px;
    border-radius: 10px;
    background: #ccc;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

// 黑色分页
.black-pagination {
  .el-pagination {
    .btn-prev,
    .btn-next,
    .el-pager,
    .btn-quicknext,
    .btn-quickprev,
    .number {
      background: transparent !important;
      color: rgba(255, 255, 255, 0.85) !important;
      font-size: 16px;
    }
    .active {
      color: $themeColor !important;
    }
  }
}

// 白色分页
.white-pagination {
  .el-pagination {
    .btn-prev,
    .btn-next,
    .el-pager,
    .btn-quicknext,
    .btn-quickprev,
    .number {
      background: transparent !important;
      color: #000 !important;
      font-size: 16px;
    }
    .active {
      color: $themeColor !important;
    }
  }
}
</style>
