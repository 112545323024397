import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index';

const routerPush = Router.prototype.push;
Router.prototype.push = function (location) {
  return routerPush.call(this, location).catch((err) => { });
}

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/design'
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/pages/Login')
    },
    {
      path: '/design',
      name: 'Desin',
      component: () => import('@/pages/Design'),
      children: [{
        path: 'home',
        name: 'Home',
        meta: {
          tabKey: 'Home'
        },
        component: () => import('@/pages/DesignHome'),
      }, {
        path: 'center',
        name: 'Center',
        meta: {
          tabKey: 'Center'
        },
        component: () => import('@/pages/DesignCenter'),
      }, {
        path: 'person',
        name: 'Person',
        component: () => import('@/pages/UserInfo/person'),
      }, {
        path: 'personConfig',
        name: 'PersonConfig',
        component: () => import('@/pages/UserInfo/personConfig'),
      }, {
        path: 'planDetail',
        name: 'PlanDetail',
        meta: {
          title: [{
            label: '个人方案',
            path: '/design/center'
          }],
          curTitle: '方案详情',
          tabKey: 'Center'
        },
        component: () => import('@/pages/PlanDetail'),
      }, {
        path: 'projectDetail',
        name: 'ProjectDetail',
        meta: {
          title: [{
            label: '项目管理',
            path: '/design/center'
          }],
          curTitle: '项目详情',
          tabKey: 'Center'
        },
        component: () => import('@/pages/ProjectDetail'),
      }, {
        // 加入项目
        path: 'projectJoin',
        name: 'ProjectJoin',
        meta: {
          title: [{
            label: '项目管理',
            path: '/design/center'
          }],
          curTitle: '项目详情',
          tabKey: 'Center'
        },
        component: () => import('@/pages/projectJoin'),
      }, {
        path: 'imagePlan',
        name: 'ImagePlan',
        meta: {
          title: [{
            label: '案例中心',
            path: '/design/center'
          }],
          curTitle: '方案详情'
        },
        component: () => import('@/pages/PlanDetail/components/PlanTabDetail/components/ImagePlan'),
      }, {
        path: 'moduleDetail',
        name: 'ModuleDetail',
        meta: {
          title: [{
            label: '素材库',
            path: '/design/center'
          }],
          curTitle: '模型详情',
          tabKey: 'Center'
        },
        component: () => import('@/pages/DesignCenter/components/Material/components/ModuleDetail')
      }, {
        // 面包屑没测试
        path: 'addModule',
        name: 'AddModule',
        meta: {
          title: [{
            label: '设计中心',
            path: '/design/center'
          }],
          curTitle: '上传模型'
        },
        component: () => import('@/pages/DesignCenter/components/Material/components/AddModule'),
      }, {
        path: 'addMaterial',
        name: 'AddMaterial',
        component: () => import('@/pages/DesignCenter/components/Material/components/AddMaterial'),
      }, {
        path: 'billList',
        name: 'BillList',
        meta: {
          title: [{
            label: '设计中心',
            path: '/design/center'
          }, {
            label: '个人方案',
            path: '/design/center'
          }],
          curTitle: '模型清单',
          tabKey: 'Center'
        },
        component: () => import('@/pages/DesignCenter/components/Material/components/BillList'),
      },
      {
        path: 'caseCenter',
        name: 'CaseCenter',
        meta: {
          tabKey: 'CaseCenter'
        },
        component: () => import('@/pages/CaseCenter'),
      },
      {
        path: 'allCase',
        name: 'CaseAllCase',
        meta: {
          title: [{
            label: '案例中心',
            path: '/design/caseCenter'
          }],
          curTitle: '全部案例',
          tabKey: 'CaseCenter'
        },
        component: () => import('@/pages/CaseCenter/AllCase'),
      },
      {
        path: 'caseDetail',
        name: 'CaseDetail',
        meta: {
          title: [{
            label: '案例中心',
            path: '/design/caseCenter'
          }],
          curTitle: '方案详情',
          tabKey: 'CaseCenter'
        },
        component: () => import('@/pages/PlanDetail/components/PlanTabDetail/components/ImagePlan'),
      },
      {
        path: 'productCenter',
        name: 'ProductCenter',
        meta: {
          tabKey: 'ProductCenter'
        },
        component: () => import('@/pages/ProductCenter'),
      },
      {
        path: 'productLightDetail',
        name: 'ProductLightDetail',
        meta: {
          tabKey: 'ProductCenter'
        },
        component: () => import('@/pages/ProductCenter/Pages/LightDetail'),
      },
      {
        path: 'productAll',
        name: 'ProductAll',
        meta: {
          tabKey: 'ProductCenter'
        },
        component: () => import('@/pages/ProductCenter/Pages/AllProduct'),
      },
      {
        path: 'productTypeLight',
        name: 'ProductTypeLight',
        meta: {
          title: [{
            label: '产品中心',
            path: '/design/productCenter'
          }, {
            label: '智慧灯具',
            path: '/design/productAll'
          }
            // , {
            //   label: '灯具类型',
            //   path: '/design/productAll'
            // }
          ],
          curTitle: '射灯',
          tabKey: 'ProductCenter'
        },
        component: () => import('@/pages/ProductCenter/Pages/ProductType'),
      },
      {
        path: 'productTypePanel',
        name: 'ProductTypePanel',
        meta: {
          title: [{
            label: '产品中心',
            path: '/design/productCenter'
          }, {
            label: '智慧面板',
            path: '/design/productAll'
          }],
          curTitle: '如梦令',
          tabKey: 'ProductCenter'
        },
        component: () => import('@/pages/ProductCenter/Pages/ProductType'),
      },
      {
        path: 'productTypeControl',
        name: 'ProductTypeControl',
        meta: {
          title: [{
            label: '产品中心',
            path: '/design/productCenter'
          }, {
            label: '控制系统',
            path: '/design/productAll'
          }],
          curTitle: 'UniHome主网关',
          tabKey: 'ProductCenter'
        },
        component: () => import('@/pages/ProductCenter/Pages/ProductType'),
      },
      {
        path: 'growUp',
        name: 'GrowUp',
        meta: {
          tabKey: 'GrowUp'
        },
        component: () => import('@/pages/GrowUp'),
      },
      {
        path: 'lightAll',
        name: 'LightAll',
        meta: {
          tabKey: 'GrowUp'
        },
        component: () => import('@/pages/GrowUp/pages/AllLight'),
      },
      {
        path: 'press',
        name: 'Press',
        meta: {
          tabKey: 'Press'
        },
        component: () => import('@/pages/Press'),
      },
      {
        path: 'newsCaseAll',
        name: 'NewsCaseAll',
        meta: {
          tabKey: 'Press'
        },
        component: () => import('@/pages/Press/Pages/AllCase'),
      },
      {
        path: '',
        redirect: '/design/home'
      }]
    },
    {
      path: '/designPlan',
      name: 'DesignPlan',
      component: () => import('@/pages/DesignPlan'),
    },
    {
      path: '*',
      name: 'notfount',
      component: () => import('@/components/Notfount'),
    }
  ],
  scrollBehavior(to, from, scrollBehavior) {
    return { x: 0, y: 0 }
  }

})
router.beforeEach((to, from, next) => {
  const toName = to?.name?.slice(0, 4)
  if (to.path === '/login') {
    next()
  }
  else if (toName !== 'Center') {
    next()
  }
  else {
    const token = JSON.parse(localStorage.getItem('userInfo'))?.token || ''
    if (token) {
      next()
    } else {
      localStorage.setItem('routerName', to.name)
      localStorage.setItem('routerUrl', to.fullPath)
      next('/login')
    }
  }
})

export default router
