import request from '@/utils/request';

export default {
    // 预约定制
    fetchSetBookingInfo(data) {
        return request({
            url: '/booking/setBookingInfo',
            method: 'post',
            data
        })
    },
    // 埋点保存
    fetchSaveEvent(data){
        return request({
            url: '/event/save',
            method: 'post',
            data
        })
    }
}
