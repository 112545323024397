import axios from './http';

function request ({ method = 'get', url, data = {}, params = {} }) {
    return axios({
        url,
        method,
        data,
        params
    })
}

export default request
