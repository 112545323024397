import Vue from 'vue'
import Vuex from 'vuex'
import { myFlat } from '@/utils/help';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    centerTabKey: 1,
    loading: false,
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
    productHoverList: [] // 产品中心滑过和全部数据
  },
  mutations: {
    // 保存用户信息
    changeUserInfo(state, payload) {
      state.userInfo = payload
      localStorage.setItem('userInfo', JSON.stringify(payload))
    },

    // loading改变
    changeState(state, payload) {
      state[payload.key] = payload.state
    },

    // 获取埋点数据
    saveSpmState(state, payload) {
      let list = JSON.parse(localStorage.getItem('spmState')) || []
      list.push(payload)
      localStorage.setItem('spmState', JSON.stringify(myFlat(list)))
    },
  },
  actions: {
  },
})
