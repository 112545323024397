import { Message } from 'element-ui';

import axios from "axios";
import FileSaver from "file-saver";
import JSZip from "jszip";
import moment from 'moment';
import CryptoJS from "crypto-js";

// 复制文本
export const copytext = (value) => {
    // 模拟 输入框
    var cInput = document.createElement("input");
    cInput.value = value;
    document.body.appendChild(cInput);
    cInput.select(); // 选取文本框内容
    // 执行浏览器复制命令
    // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
    // Input要在正常的编辑状态下原生复制方法才会生效
    document.execCommand("copy");
    // 复制成功后再将构造的标签 移除
    document.body.removeChild(cInput);
}

// 下载图片
const download = (href, name) => {
    let eleLink = document.createElement('a')
    eleLink.download = name
    eleLink.href = href
    eleLink.click()
    eleLink.remove()
}

// 下载图片
export const downloadByBlob = (url, name, style) => {
    let image = new Image()
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
    image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = style?.width || image.width
        canvas.height = style?.height || image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)

        canvas.toBlob((blob) => {
            let url = URL.createObjectURL(blob)
            download(url, name)
            // 用完释放URL对象
            URL.revokeObjectURL(url)
        })
    }
};

const getFile = (url) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url,
            responseType: "blob",
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.toString());
            });
    });
}

// 批量下载图片
export const batchDownLoad = (mapLists) => {
    const zip = new JSZip();
    const promises = [];
    const cache = {};
    // 遍历图片数组
    for (const item of mapLists) {
        const suffixIndex = item.img.lastIndexOf(".");
        // 图片后缀名
        const suffix = item.img.substring(suffixIndex + 1);
        const promise = getFile(item.img).then((data) => {
            // 下载文件, 并存成ArrayBuffer对象
            // 文件名
            zip.file(`${item.roomName}.${suffix}`, data, {
                binary: true,
            }); // 逐个添加文件，需要加后缀".png"
            cache[item.roomName] = data;
        });
        promises.push(promise);
    }
    Promise.all(promises)
        .then(() => {
            zip.generateAsync({ type: "blob" }).then((content) => {
                // 生成二进制流
                FileSaver.saveAs(content, `${mapLists[0].roomName}`); // 利用file-saver保存文件  自定义文件名
                Message.success('下载完成')
            });
        })
        .catch((res) => {
            Message.success('下载失败!')
        });
}

// 时间转换
export const dateTransform = (list, key, mode) => {
    const endDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    list?.forEach(item => {
        const startDate = moment(item[key]).format('YYYY-MM-DD HH:mm:ss');
        const days = moment(endDate).diff(startDate, 'day')
        const hour = moment(endDate).diff(startDate, 'hour')
        const second = moment(endDate).diff(startDate, 'second')
        item.cityName = item?.city?.split(' ')?.[1] || item?.city?.split(' ')?.[0]
        if (mode === 'detail') {
            if (days < 7) {
                if (days < 1) {
                    if (hour < 1) {
                        item.createDate = `${Math.floor(second / 60)}分${second % 60 < 10 ? `0${second % 60}` : second % 60}秒前`
                    } else {
                        item.createDate = `${hour}小时前`
                    }
                } else {
                    item.createDate = `${days}天前`
                }
            } else {
                item.createDate = '一周前'
            }
        } else {
            item.createDate = `${days}天前`
        }
    })
    return list
}

export const dateTransforms = (list, key, mode, itemKey = 'createDate') => {
    const endDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    list?.forEach(item => {
        const startDate = moment(item[key]).format('YYYY-MM-DD HH:mm:ss');
        const days = moment(endDate).diff(startDate, 'day')
        const hour = moment(endDate).diff(startDate, 'hour')
        const second = moment(endDate).diff(startDate, 'second')
        item.cityName = item?.city?.split(' ')?.[1] || item?.city?.split(' ')?.[0]
        if (mode === 'detail') {
            if (days < 7) {
                if (days < 1) {
                    if (hour < 1) {
                        item[itemKey] = `${Math.floor(second / 60)}分${second % 60 < 10 ? `0${second % 60}` : second % 60}秒前`
                    } else {
                        item[itemKey] = `${hour}小时前`
                    }
                } else {
                    item[itemKey] = `${days}天前`
                }
            } else {
                item[itemKey] = '一周前'
            }
        } else {
            item.itemKey = `${days}天前`
        }
    })
    return list
}

// 增加disabled属性
export const dataAddDisabled = (list) => {
    list.forEach(item => {
        item.disabled = item?.checked
    })
    return list
}

// 增加check属性
export const dataAddCheck = (list) => {
    list.forEach(item => {
        item.checked = false
    })
    return list
}

// 防抖
export const debounce = (fn, delay) => {
    let timer;
    return function () {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            // 没有参数的时候用fn()就够用了
            // 有的函数会有参数，必须用到this指向才能把参数传过去;
            fn.call(this, ...arguments);
        }, delay);
    };
};

// 脱敏公共方法
export const encryptPublic = (str, kind) => {
    if (!str) return ''
    if (kind == 'name') {
        return str.substr(0, 1) + '*'
    }
    if (kind == 'idcard') {
        return str.replace(/^(.{6})(?:\d+)(.{4})$/, '$1********$2')
    }
    if (kind == 'phone') {
        return str.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
    }
    if (kind == 'email') {
        return str.split("@")[0].substr(0, str.split("@")[0].length - 8) + "***********" + str.split("@")[1].substr(2);
    }
}

// 数组扁平化
export const myFlat = (arr) => {
    return arr?.reduce((pre, cur) => {
        return pre?.concat(Array.isArray(cur) ? myFlat(cur) : cur);
    }, [])
}

// 获取url参数
export const getUrlParams = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.href);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

// 十六位十六进制数作为密钥
const SECRET_KEY = CryptoJS.enc.Utf8.parse("1234123412341234");
// 十六位十六进制数作为密钥偏移量
const SECRET_IV = CryptoJS.enc.Utf8.parse("1234123412341234");

/**
 * 加密方法
 * @param data
 * @returns {string}
 */
export const encrypt = (data) => {
    if (typeof data === "object") {
        try {
            // eslint-disable-next-line no-param-reassign
            data = JSON.stringify(data);
        } catch (error) {
            console.log("encrypt error:", error);
        }
    }
    const dataHex = CryptoJS.enc.Utf8.parse(data);
    const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
        iv: SECRET_IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString();
}

/**
 * 解密方法
 * @param data
 * @returns {string}
 */
export const decrypt = (data) => {
    const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
    const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
        iv: SECRET_IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}